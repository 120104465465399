import { Button, MenuItem, Select, Snackbar, TextField, InputLabel, FormControl } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import DatePicker from '../../core/components/DatePicker/DatePicker';
import './ImageryNewSearchFilter.css';
import { getvalueCodes, searchImagery } from '../../core/services/ImageryNewService'
import StackedBarChart from '../../core/components/StackedBarChart/StackedBarChart';
import DataGrid from '../../core/components/DataGrid/DataGrid';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 280,
        },
    },
};


export default class ImageryNewSearchFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jNumber: null,
            startDate: null,
            endDate: null,
            cardType: '',
            cardBarCode: null,
            valueCode: '',
            fileName: null,
            cardTypeCode: '',
            valueCodes: [],
            searchCriteria: {},
            tableData: [],
            rawData: [],
            openSnackBar: false,
            errMessage: ''
        }
        this.updateTableData = this.updateTableData.bind(this);
        this.linkClicked = this.linkClicked.bind(this);
        this.setRawDataEmpty = this.setRawDataEmpty.bind(this);
        this.handleCloseSnackBar = this.handleCloseSnackBar.bind(this);
    }

    setRawDataEmpty() {
        this.setState({ rawData: [] });
    }

    linkClicked(rowData) {
        // console.log(rowData);
        this.props.setImgSrc('imageattributesfile/' + rowData.id);
        this.props.openDialog();
    }

    updateTableData(data) {
        const tableData = data?.map(val => {
            return {
                J_NUMBER: val['SerialNumber'],
                CWG_1: val['CompositeWellGrade1_DisplayValue'],
                CWG_2: val['CompositeWellGrade2_DisplayValue'],
                CWG_3: val['CompositeWellGrade3_DisplayValue'],
                CWG_4: val['CompositeWellGrade4_DisplayValue'],
                CWG_5: val['CompositeWellGrade5_DisplayValue'],
                CWG_6: val['CompositeWellGrade6_DisplayValue'],
                FILENAME: "Show Image",
                id: val['ImageAttributeJPG_Id']
            }
        });
        this.setState({ tableData, rawData: data });
    }

    handleCloseSnackBar() {
        this.setState({ openSnackBar: false });
    }


    formatSearchJson(json) {
        const keys = Object.keys(json);
        return keys.reduce((acc, val, ind) => {
            return acc + " " + val + ": [" + json[val] + (ind >= keys.length - 1 ? ']' : "], ");
        }, '')
    }


    jnumberChange = (e) => {
        this.setState({ jNumber: e.target.value });
    }

    startDateChange = (e) => {
        // console.log(e.target.value);
        this.setState({ startDate: e.target.value });
    }

    endDateChange = (e) => {
        // console.log(e.target.value);
        this.setState({ endDate: e.target.value });
    }

    cardTypeChange = (e) => {
        // console.log(e.target.value);
        this.setState({ cardType: e.target.value, cardTypeCode: -1 });
    }

    cardBarCodeChange = (e) => {
        // console.log(e.target.value);
        this.setState({ cardBarCode: e.target.value });
    }

    valueCodeChange = (e) => {
        // console.log(e.target.value);
        this.setState({ valueCode: e.target.value });
    }

    fileNameChange = (e) => {
        // console.log(e.target.value);
        this.setState({ fileName: e.target.value });
    }
    cardTypeCodeChange = (e) => {
        // console.log(e.target.value);
        this.setState({ cardTypeCode: e.target.value });
    }

    search = () => {
        const criteria = {
            jNumber: this.state.jNumber,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
        if ((criteria.startDate === null || criteria.startDate === undefined) || (criteria.endDate === null || criteria.endDate === undefined)) {
            this.setState({ openSnackBar: true, errMessage: 'Start Date and End Date are mandatory Fields. Please fill the fields and search again!!!' });
            return;
        }
        if (this.state.cardType && this.state.cardType !== -1 && this.state.cardType !== '-1') {
            criteria.cardType = this.state.cardType;

        }
        if (this.state.cardBarCode) {
            criteria.cardBarCode = this.state.cardBarCode;

        }
        if (this.state.valueCode && this.state.valueCode !== -1 && this.state.valueCode !== '-1') {
            criteria.valueCode = this.state.valueCode;
        }
        if (this.state.fileName) {
            criteria.fileName = this.state.fileName;
        }
        if (this.state.cardTypeCode && this.state.cardTypeCode !== -1 && this.state.cardTypeCode !== '-1') {
            criteria.cardTypeCode = this.state.cardTypeCode;
        }
        this.setState({ searchCriteria: criteria });
        this.setRawDataEmpty();
        this.props.startLoading();
        // searchImagery(criteria).then(res => res.json(), err => console.log(err))
        searchImagery(criteria).then(({ data }) => {
            if (data && !data.hasOwnProperty('Error')) {
                this.updateTableData(data);
            }
            this.props.stopLoading();
        })
    }

    getSearchCriteriaStringObject() {
        const { jNumber, startDate, endDate, cardType, cardBarCode, valueCode, fileName, cardTypeCode } = this.state.searchCriteria;
        const printObj = {
            'JNumber': jNumber,
            'Start Date': startDate,
            'End Date': endDate,
            'Card Type': cardType,
            'Card Type Code': cardTypeCode,
            'Card Bar Code': cardBarCode,
            'Value Code': valueCode,
            'File Name': fileName
        }
        Object.keys(printObj).forEach(key => (printObj[key] === undefined || printObj[key] === null) && delete printObj[key]);
        return printObj;

    }

    componentDidMount() {
        this.props.startLoading();
        // getvalueCodes().then(response => response.json(), err => console.log(err))
        getvalueCodes().then(response => {
            this.setState({ valueCodes: response.data });
            this.props.stopLoading();
        })
    }

    render() {
        const columns = [
            {
                id: 'J_NUMBER',
                label: 'JNumber',
                minWidth: 80,
                align: 'center'
            },
            {
                id: 'CWG_1',
                label: 'Composite WellGrade1',
                minWidth: 100,
                align: 'center'
            },
            {
                id: 'CWG_2',
                label: 'Composite WellGrade2',
                minWidth: 100,
                align: 'center'
            },
            {
                id: 'CWG_3',
                label: 'Composite WellGrade3',
                minWidth: 100,
                align: 'center'
            },
            {
                id: 'CWG_4',
                label: 'Composite WellGrade4',
                minWidth: 100,
                align: 'center'
            },
            {
                id: 'CWG_5',
                label: 'Composite WellGrade5',
                minWidth: 100,
                align: 'center'
            },
            {
                id: 'CWG_6',
                label: 'Composite WellGrade6',
                minWidth: 100,
                align: 'center',
            },
            {
                id: 'FILENAME',
                label: 'View Image',
                minWidth: 100,
                align: 'center',
                type: 'link'
            }
        ];
        const bioVueOptions = [{ value: 0, displayType: 'ABO-Rh / Reverse Grouping' },
        { value: 10, displayType: 'ABD Confirmation' },
        { value: 11, displayType: 'Rh-hr' },
        { value: 14, displayType: 'Rh / K II' },
        { value: 20, displayType: 'Newborn' },
        { value: 22, displayType: 'Poly' },
        { value: 30, displayType: 'DAT / IDAT' },
        { value: 33, displayType: 'IgG' },
        { value: 40, displayType: 'ADK' },
        { value: 44, displayType: 'ABO-Rh Grouping' },
        { value: 46, displayType: 'ABD/Reverse Grouping' },
        { value: 48, displayType: 'ABO-DD Grouping' },
        { value: 55, displayType: 'Poly / Neutral' },
        { value: 66, displayType: 'Reverse Diluent' },
        { value: 77, displayType: 'Rh / K' },
        { value: 88, displayType: 'Neutral' },
        { value: 90, displayType: 'K' },
        { value: 95, displayType: 'Kell / Control' }];
        const gelTypeOptions = [{ value: 53, displayType: 'A/B/D Monoclonal Grouping' },
        { value: 48, displayType: 'Monoclonal Rh Phenotype' },
        { value: '05', displayType: 'Anti-IgG, -C3d' },
        { value: '01', displayType: 'Anti-IgG' },
        { value: 37, displayType: 'A/B/D Monoclonal and Reverse Grouping' },
        { value: '04', displayType: 'Buffered Gel' },
        { value: 57, displayType: 'A/B Monoclonal Grouping' },
        { value: 39, displayType: 'Anti-A (Murine Monoclonal)' },
        { value: 40, displayType: 'Anti-B (Murine Monoclonal)' },
        { value: 38, displayType: 'Anti-A,B (Murine Monoclonal Blend)' },
        { value: 45, displayType: 'Anti-C (Monoclonal)' },
        { value: 41, displayType: 'Anti-D (Monoclonal) (IgM)' },
        { value: 47, displayType: 'Anti-E (Monoclonal)' },
        { value: 44, displayType: 'Anti-c (Monoclonal)' },
        { value: 46, displayType: 'Anti-e (Monoclonal)' },
        { value: 42, displayType: 'Monoclonal Control' },
        { value: 60, displayType: 'DAT' }];

        function getCardTypeOptions(arr) {
            return arr.map((val, ind) =>
                <MenuItem aria-label={val.displayType} key={ind} value={val.value}>{val.displayType}
                </MenuItem>);
        }

        return (
            <div>
                <div className="filter-main1">
                    <div className="filters">
                        <div className="filter-jnumber">
                            <TextField id="standard-basic" label="JNumber" onChange={(e) => this.jnumberChange(e)} />
                        </div>
                        <div className="filter-cardType">
                            <FormControl>
                                <InputLabel className="select-label" id="card-type">Card type</InputLabel>
                                <Select
                                    labelId='card-type'
                                    variant='standard'
                                    value={this.state.cardType}
                                    onChange={this.cardTypeChange}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem aria-label="None" value={-1} >None</MenuItem>
                                    <MenuItem value={'MTSGELCard'}>MTSGELCard</MenuItem >
                                    <MenuItem value={'BiovueCassette'}>BiovueCassette</MenuItem >
                                </Select>
                            </FormControl>
                        </div>
                        {(this.state.cardType && (this.state.cardType !== -1 && this.state.cardType !== '-1')) ? <div className="filter-cardType">
                            <FormControl>
                                <InputLabel className="select-label" id="card-type-code">Card type code</InputLabel>
                                <Select
                                    MenuProps={MenuProps}
                                    value={this.state.cardTypeCode}
                                    onChange={this.cardTypeCodeChange}
                                    labelId="card-type-code"
                                >
                                    <MenuItem aria-label="None" value={-1} >None</MenuItem>
                                    {this.state.cardType === 'MTSGELCard' ? getCardTypeOptions(gelTypeOptions) : getCardTypeOptions(bioVueOptions)}
                                </Select>
                            </FormControl>
                        </div> : <div className="filter-cardType" title='Please select a card type to enable this field'>
                            <FormControl>
                                <InputLabel className="select-label" id="card-type-code">Card type code</InputLabel>
                                <Select
                                    value={this.state.cardTypeCode}
                                    onChange={this.cardTypeChange}
                                    labelId="card-type-code"
                                    disabled={true}
                                >
                                    <option aria-label="None" value="-1" >None</option>
                                    {/* {this.state.cardType === 'MTSGELCard' ? getCardTypeOptions(gelTypeOptions) : getCardTypeOptions(bioVueOptions)} */}
                                </Select>
                            </FormControl>
                        </div>}

                        <div className="filter-cardBarCode">
                            <TextField id="standard-basic-card-bar-code" label="Card Bar Code" type="number" onChange={(e) => this.cardBarCodeChange(e)} />
                        </div>
                        <div className="filter-valueCode">
                            <FormControl>
                                {/* <TextField id="standard-basic-value-code" label="Value Code" onChange={(e) => this.valueCodeChange(e)} /> */}
                                <InputLabel className="select-label" id="value-code">Value code</InputLabel>
                                <Select

                                    value={this.state.valueCode}
                                    onChange={this.valueCodeChange}
                                    MenuProps={MenuProps}
                                    labelId="value-code"
                                >
                                    <MenuItem aria-label="None" value="-1" >None</MenuItem>
                                    {this.state.valueCodes.map((val, ind) => {
                                        return (<MenuItem aria-label="None" key={ind} value={val.CalculatedGradedResult} >{val.CalculatedGradedResult}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="filter-fileName">
                            <TextField id="standard-basic-fileName" label="File Name" onChange={(e) => this.fileNameChange(e)} />
                        </div>

                    </div>
                    <div className="row1">
                        <div className="filter-daterange">
                            <div>
                                <DatePicker error={this.state.openSnackBar} id="start-date" label="Start Date" required defaultValue="" onChange={(e) => this.startDateChange(e)} ></DatePicker>
                            </div>
                            <div>
                                <DatePicker error={this.state.openSnackBar} id="end-date" label="End Date" required defaultValue="" onChange={(e) => this.endDateChange(e)} ></DatePicker>
                            </div>
                            <div className="search-btn">
                                <Button variant="outlined" onClick={this.search}>Search</Button>
                            </div>
                        </div>
                        <div className="search-criteria">
                            <em> {Object.keys(this.state.searchCriteria).length > 0 ? 'Search Criteria: ' + this.formatSearchJson(this.getSearchCriteriaStringObject()) : 'No search criteria defined.'}</em>
                        </div>


                        {this.state.rawData?.length > 0 ? (
                            <React.Fragment>
                                <StackedBarChart data={this.state.rawData} title={'Composite well grade count by day'}  ></StackedBarChart>
                                <DataGrid rows={this.state.tableData} columns={columns} linkClicked={(column) => this.linkClicked(column)} />
                            </React.Fragment>
                        ) : Object.keys(this.state.searchCriteria).length > 0 ? (<div className="no-data"><em>No data to display Graph.</em></div>) : ''}
                    </div>

                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.openSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
                        <Alert onClose={this.handleCloseSnackBar} severity="info">
                            {this.state.errMessage}
                        </Alert>
                    </Snackbar>
                </div>
                {/* {this.state.rawData?.length > 0 ? (
                    <React.Fragment>
                        <DataGrid rows={this.state.tableData} columns={columns} linkClicked={(column) => this.linkClicked(column)} />
                    </React.Fragment>
                ) : <div className="no-data">No data to display in table.</div>} */}
            </div>
        );
    }
}