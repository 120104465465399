import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import ImageryNew from './components/ImageryNew/ImageryNew';
import UserGuide from './components/UserGuide/UserGuide';
import Nav from './core/components/Nav/Nav';
import { CircularProgress } from '@material-ui/core';
import ImageryDialog from './core/components/Dialog/Dialog';
import { currentConfig } from './utils/amplify-config';
import Amplify, { Auth } from 'aws-amplify';
import { callApi } from './utils/auth-utilities';

Amplify.configure(currentConfig);

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openDialog: false,
      imgSrc: "#",
      userSession: false
    };
    this.setImgSrc = this.setImgSrc.bind(this);

  }

  startLoader = () => {
    this.setState({ isLoading: true });
  }
  stopLoader = () => {
    this.setState({ isLoading: false });
  }

  openDialog = () => {
    this.setState({ openDialog: true });
  }

  closeDialog = () => {
    this.setState({ openDialog: false, imgSrc: null });
  }

  setImgSrc(src) {
    this.startLoader();
    callApi(src, {}, { "Accept": "*/*", "Content-Type": "image/jpeg" }, "GET").then(res => {
      // console.log(res, "ressssssssss")
      this.setState({ imgSrc: res?.data });
      this.stopLoader();
    })

  }

  componentDidMount() {
    // console.log(Auth, "auth creds", Auth.Credentials, Auth._storage)
    Auth.currentAuthenticatedUser()
      .then(user => console.log({ user }))
      .catch(() => {
        // console.log("Not signed in redirecting to sign in");
        Auth.federatedSignIn();
      });
    const getSession = async () => {
      await Auth.currentSession().then(userSession => {
        this.setState({ userSession });
      });
    }
    getSession();
  }

  render() {
    if (this.state.userSession && this.state.userSession?.isValid()) {
      return (
        <BrowserRouter>
          <div className="App">
            <div>
              <ImageryDialog open={this.state.openDialog} close={() => this.closeDialog()} imgSrc={this.state.imgSrc} />
            </div>
            <div className="navbar">
              <Nav></Nav>
            </div>
            <div className="app-body">
              <Switch>
                {/* <Route exact path="/">
                <Home openDialog={this.openDialog} startLoading={this.startLoader} stopLoading={this.stopLoader} />
              </Route>
              <Route path="/imagery">
                <Imagery />
              </Route> */}
                <Route
                  exact
                  path="/"
                  render={() => {
                    return (<Redirect to="/imageryNew" />)
                  }}
                />
                <Route path="/userGuide">
                  <UserGuide />
                </Route>
                <Route path="/imageryNew" >
                  <ImageryNew setImgSrc={this.setImgSrc} openDialog={this.openDialog} startLoading={this.startLoader} stopLoading={this.stopLoader} />
                </Route>
              </Switch>
            </div>
          </div>
          {
            this.state.isLoading ? <div className="app-loader">
              <CircularProgress color="secondary" />
            </div> : ''
          }
        </BrowserRouter>
      );
    } else {
      return (
        <>
          You will be automatically redirected or{" "}
          <span style={{ "textDecoration": "underline", "color": "steelblue" }} onClick={Auth.federatedSignIn}>
            click here to login
        </span>
        .
        </>
      );
    }
  }
}

export default App;
